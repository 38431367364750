import { createQueryClient } from "@saas/core/react-query/create-query-client/create-query-client";
import { queryClient } from "@saas/core/react-query/query-client/query-client";

import {
  getFeatureFlagsStatus,
  GetFeatureFlagsStatusOutput,
} from "../../get-feature-flags-status/get-feature-flags-status";
import { featureFlagsQueryKey } from "../feature-flags/feature-flags.query-key";

import { Updater } from "@tanstack/react-query";

export const setUseFeatureFlagsStatusQuery = (
  updater: Updater<
    GetFeatureFlagsStatusOutput | undefined,
    GetFeatureFlagsStatusOutput
  >
): void => {
  queryClient.setQueryData<GetFeatureFlagsStatusOutput>(
    featureFlagsQueryKey.status(),
    updater
  );
};

export const prefetchUseFeatureFlagsStatusQuery =
  async (): Promise<GetFeatureFlagsStatusOutput> => {
    const client = createQueryClient();

    const data = await getFeatureFlagsStatus();

    await client.prefetchQuery(featureFlagsQueryKey.status(), () => data);

    return data;
  };

export const invalidateUseFeatureFlagsStatusQuery = () => {
  return queryClient.invalidateQueries(featureFlagsQueryKey.status());
};
