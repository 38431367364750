"use client";

import { useRouter } from "next/router";
import { memo, ReactElement, useCallback, useEffect, useMemo } from "react";

import { useSessionProfile } from "../@hook/session-profile/use-session-profile";

import { useIsClient } from "usehooks-ts";

export interface UseAccountVerificationGuardProps {
  children: ReactElement;
}

export const AccountVerificationGuard = memo(
  ({ children }: UseAccountVerificationGuardProps) => {
    const { profile } = useSessionProfile();
    const { pathname, push } = useRouter();
    const isClient = useIsClient();

    const allowAccessForVerified = useMemo(() => {
      if (!profile?.emailVerified) {
        return false;
      }

      return true;
    }, [profile?.emailVerified]);

    const redirectToAccountActivation = useCallback(async () => {
      if (
        isClient &&
        !["/account-activation", "/setup-password"].includes(pathname)
      ) {
        await push("/account-activation");
      }
    }, [isClient, pathname, push]);

    useEffect(() => {
      if (!allowAccessForVerified) {
        redirectToAccountActivation();
      }
    }, [allowAccessForVerified, redirectToAccountActivation]);

    return allowAccessForVerified ? children : null;
  }
);

AccountVerificationGuard.displayName = "AccountVerificationGuard";

export default AccountVerificationGuard;
