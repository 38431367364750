"use client";

import { useRouter } from "next/router";
import { useEffect } from "react";

import {
  getVerification,
  sendVerificationEmail,
  useSendVerificationQuery,
} from "@saas/account/data-access";
import { invalidateUseSessionQuery } from "@saas/account/data-access/@query/session/use-session.query.utils";
import {
  setGTagUserVerifiedProperty,
  trackResendVerificationLink,
} from "@saas/account/utils";
import { env } from "@saas/config/shared";
import { useAlert } from "@saas/shared/feature";
import { NotificationBar } from "@saas/shared/ui";
import { notify } from "@saas/shared/utils";
import { AlertDispatch } from "@saas/shared/utils/@interface/alert/alert.interface";
import {
  emailVerificationCode,
  emailVerificationStatus,
} from "@saas/shared/utils/email-verification-status/email-verification-status";

import useSessionProfile from "../@hook/session-profile/use-session-profile";

const DASHBOARD_PATH = "/";
const VERIFICATION_STATUS = {
  EXPIRED: {
    state: "choose_method",
    code: emailVerificationCode.VERIFICATION_EXPIRED,
    status: emailVerificationStatus.EXPIRED,
    message: "Link verifikasi sudah tidak berlaku, harap kirim ulang.",
    variant: "negative" as const,
  },
  VERIFIED: {
    state: "passed_challenge",
    code: emailVerificationCode.VERIFICATION_PASSED,
    status: emailVerificationStatus.VERIFIED,
    message: "Hore! Email sudah berhasil diverifikasi.",
    variant: "positive" as const,
  },
} as const;

export const VerificationNotification = () => {
  const { asPath, query, push } = useRouter();
  const { profile } = useSessionProfile();
  const { data: flow } = useSendVerificationQuery(!profile?.emailVerified);
  const { flow: flowId } = query;
  const [, dispatch] = useAlert();
  const isDashboard = asPath.split("?")[0] === DASHBOARD_PATH;

  const handleVerificationSuccess = async () => {
    setGTagUserVerifiedProperty(true);
    await invalidateUseSessionQuery();
    await push(DASHBOARD_PATH);
  };

  const sendVerification = async () => {
    if (flow && profile) {
      try {
        const { state } = await sendVerificationEmail({
          flowId: flow.id,
          email: profile.email,
          csrfToken: flow.csrfToken,
        });

        if (state === "sent_email") {
          trackResendVerificationLink();
          notify(dispatch, {
            variant: "positive",
            content:
              "Link verifikasi sudah terkirim! Harap cek email untuk melakukan verifikasi.",
            testid: "home__snackbar__link-sent",
          });
        }
      } catch (err) {
        notify(dispatch, {
          variant: "negative",
          content: "Link verifikasi gagal terkirim, harap kirim ulang.",
          testid: "home__snackbar__link-sent",
        });
      }
    }
  };

  useEffect(() => {
    const isVerificationPath = asPath.split("?")[0] === DASHBOARD_PATH;

    if (isVerificationPath && flowId) {
      getVerification(flowId as string).then(async ({ state, code }) => {
        await handleVerificationStatus({
          state,
          code,
          registeredOn: profile?.registeredOn,
          dispatch,
          onVerificationSuccess: handleVerificationSuccess,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, flowId, profile?.emailVerified, push, dispatch]);

  return !profile?.emailVerified && !isDashboard ? (
    <NotificationBar
      title={"Kamu belum melakukan verifikasi email."}
      message={`Cek email-mu untuk melakukan verifikasi email agar kamu dapat menggunakan ${env.APP_NAME} dengan maksimal.`}
      action={{
        label: "Kirim ulang link verifikasi",
        onClick: sendVerification,
        testid: "marketplace-connection__notification-bar__link",
      }}
      testid={"marketplace-connection__notification-bar"}
    />
  ) : null;
};

type HandleVerificationParams = {
  state: string;
  code?: number;
  registeredOn?: string;
  dispatch: AlertDispatch;
  onVerificationSuccess: () => Promise<void>;
};

export const handleVerificationStatus = async ({
  state,
  code,
  registeredOn,
  dispatch,
  onVerificationSuccess,
}: HandleVerificationParams) => {
  const status = Object.values(VERIFICATION_STATUS).find(
    (s) => s.state === state && s.code === code
  );
  const isNotDashboardAccount =
    registeredOn && registeredOn !== env.DASHBOARD_URL;

  if (!status) return;

  if (isNotDashboardAccount) {
    const registeredOnUrl = new URL(registeredOn);
    registeredOnUrl.searchParams.set("verificationStatus", status.status);
    return window.location.replace(registeredOnUrl);
  }

  notify(dispatch, {
    variant: status.variant,
    content: status.message,
    testid: "home__snackbar__link-sent",
  });

  if (status.status === emailVerificationStatus.VERIFIED) {
    await onVerificationSuccess();
  }
};

export default VerificationNotification;
