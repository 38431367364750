export interface Env {
  /* Global (Application) */
  APP_ENV: string;
  APP_NAME: string;
  ROOT_DOMAIN: string;
  /* Global (URLs) */
  APP_URL: string;
  LANDING_URL: string;
  DASHBOARD_URL: string;
  CATALOG_URL: string;
  POS_URL: string;
  LOYALTY_URL: string;
  RESELLER_URL: string;
  HELP_URL: string;
  ACADEMY_URL: string;
  HELP_DISCOVER_URL: string;
  POS_PLAYSTORE_URL: string;
  TOKOPEDIA_CARE_URL: string;
  /* Global (Support) */
  SUPPORT_PHONE_NO: string;
  SUPPORT_MARKET: string;
  /* Global (External Services) */
  USETIFUL_TOKEN: string;
  GOOGLE_ANALYTICS_ID: string;
  GTM_ID: string;
  AMPLITUDE_API_KEY: string;
  AMPLITUDE_REPLAY_SAMPLE_RATE: string;
  ONESIGNAL_APP_ID: string;
  AMPLITUDE_TIMEOUT: number;
  /* Global (Microservices) */
  WEBSOCKET_CHAT_URL: string;
  /* Global (Mocks) */
  API_MOCKING: boolean;
  WEBSOCKET_MOCKING: boolean;
  /* Dashboard (Templates) */
  ACCOUNTING_TEMPLATE_LINK: string;
  BROADCAST_TEMPLATE_LINK: string;
  /* Policy Url */
  TNC_URL: string;
  PRIVACY_POLICY_URL: string;
  RETURN_POLICY_URL: string;
  CATALOG_POLICY_URL: string;
  LOYALTY_POLICY_URL: string;
  /* Marketing Promotion basic auth */
  MARKETING_PROMOTION_SERVICE_USERNAME: string;
  MARKETING_PROMOTION_SERVICE_PASSWORD: string;
  /**
   * SUBBACOUNT_USER_LIMIT will retrieve how many subaccount can be created.
   * if config didn't exists in .env will fallback with the value 5.
   * 5 is initial requirement for subaccount limit.
   */
  SUBBACOUNT_USER_LIMIT: number;
  NEXT_ALLOW_SESSION_REPLAY_ON_CATALOG_IDS: ReadonlyArray<string>;
}

/**
 * @description App URL override for preview
 */
const PREVIEW_APP_URL = `https://${process.env["NEXT_PUBLIC_VERCEL_URL"]}`;
const PREVIEW_APP_ROOT_DOMAIN = String(
  process.env["NEXT_PUBLIC_VERCEL_URL"]
).toLowerCase();

/**
 * @description Landing URL override for preview
 */
const PREVIEW_LANDING_URL = `https://${process.env["NEXT_PUBLIC_GIT_REF"]}-hypefast.vercel.app`;

/**
 * @description Dashboard URL override for preview
 */
const PREVIEW_DASHBOARD_URL = `https://${process.env["NEXT_PUBLIC_GIT_REF"]}-hypefast-dashboard.vercel.app`;

/**
 * @description Catalog URL override for preview
 */
const PREVIEW_CATALOG_URL = `https://${process.env["NEXT_PUBLIC_GIT_REF"]}-hypefast-catalog.vercel.app`;

/**
 * @description PoS URL override for preview
 */
const PREVIEW_POS_URL = `https://${process.env["NEXT_PUBLIC_GIT_REF"]}-hypefast-pos.vercel.app`;

/**
 * @description Loyalty URL override for preview
 */
const PREVIEW_LOYALTY_URL = `https://${process.env["NEXT_PUBLIC_GIT_REF"]}-hypefast-loyalty.vercel.app`;

export const env: Env = {
  /* Global (Application) */
  APP_NAME: process.env["NEXT_PUBLIC_APP_NAME"] ?? "App",
  APP_ENV: process.env["NEXT_PUBLIC_APP_ENV"] ?? "dev",
  ROOT_DOMAIN:
    process.env["NEXT_PUBLIC_ROOT_DOMAIN"] ?? PREVIEW_APP_ROOT_DOMAIN,
  /* Global (URLs) */
  APP_URL: process.env["NEXT_PUBLIC_APP_URL"] ?? PREVIEW_APP_URL,
  LANDING_URL: process.env["NEXT_PUBLIC_LANDING_URL"] ?? PREVIEW_LANDING_URL,
  DASHBOARD_URL:
    process.env["NEXT_PUBLIC_DASHBOARD_URL"] ?? PREVIEW_DASHBOARD_URL,
  CATALOG_URL: process.env["NEXT_PUBLIC_CATALOG_URL"] ?? PREVIEW_CATALOG_URL,
  POS_URL: process.env["NEXT_PUBLIC_POS_URL"] ?? PREVIEW_POS_URL,
  LOYALTY_URL: process.env["NEXT_PUBLIC_LOYALTY_URL"] ?? PREVIEW_LOYALTY_URL,
  RESELLER_URL: process.env["NEXT_PUBLIC_RESELLER_URL"] ?? "",
  HELP_URL: process.env["NEXT_PUBLIC_HELP_URL"] ?? "",
  ACADEMY_URL: process.env["NEXT_PUBLIC_ACADEMY_URL"] ?? "",
  HELP_DISCOVER_URL: process.env["NEXT_PUBLIC_HELP_DISCOVER_URL"] ?? "",
  POS_PLAYSTORE_URL: process.env["NEXT_PUBLIC_POS_PLAYSTORE_URL"] ?? "",
  TOKOPEDIA_CARE_URL: process.env["NEXT_PUBLIC_TOKOPEDIA_CARE_URL"] ?? "",
  /* Global (Support) */
  SUPPORT_PHONE_NO: process.env["NEXT_PUBLIC_SUPPORT_PHONE_NO"] ?? "",
  SUPPORT_MARKET: process.env["NEXT_PUBLIC_SUPPORT_MARKET"] ?? "",
  /* Global (External Services) */
  USETIFUL_TOKEN: process.env["NEXT_PUBLIC_USETIFUL_TOKEN"] ?? "",
  GOOGLE_ANALYTICS_ID: process.env["NEXT_PUBLIC_GOOGLE_ANALYTICS_ID"] ?? "",
  GTM_ID: process.env["NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID"] ?? "",
  AMPLITUDE_API_KEY: process.env["NEXT_PUBLIC_AMPLITUDE_API_KEY"] ?? "",
  AMPLITUDE_REPLAY_SAMPLE_RATE:
    process.env["NEXT_PUBLIC_AMPLITUDE_REPLAY_SAMPLE_RATE"] ?? "0",
  ONESIGNAL_APP_ID: process.env["NEXT_PUBLIC_ONESIGNAL_APP_ID"] ?? "",
  AMPLITUDE_TIMEOUT: process.env["NEXT_PUBLIC_AMPLITUDE_TIMEOUT"]
    ? Number(process.env["NEXT_PUBLIC_AMPLITUDE_TIMEOUT"])
    : 0,
  /* Global (Microservices) */
  WEBSOCKET_CHAT_URL:
    process.env["NEXT_PUBLIC_WEBSOCKET_CHAT_URL"] ?? "ws://localhost.test",
  /* Global (Local) */
  API_MOCKING: process.env["NEXT_PUBLIC_API_MOCKING"] === "enabled",
  WEBSOCKET_MOCKING: process.env["NEXT_PUBLIC_WEBSOCKET_MOCKING"] === "enabled",
  /* Dashboard (Templates) */
  ACCOUNTING_TEMPLATE_LINK:
    process.env["NEXT_PUBLIC_ACCOUNTING_TEMPLATE_LINK"] ?? "",
  BROADCAST_TEMPLATE_LINK:
    process.env["NEXT_PUBLIC_BROADCAST_TEMPLATE_LINK"] ?? "",
  LOYALTY_POLICY_URL: process.env["NEXT_PUBLIC_LOYALTY_POLICY_URL"] ?? "",
  TNC_URL: process.env["NEXT_PUBLIC_TNC_URL"] ?? "",
  PRIVACY_POLICY_URL: process.env["NEXT_PUBLIC_PRIVACY_POLICY_URL"] ?? "",
  RETURN_POLICY_URL: process.env["NEXT_PUBLIC_RETURN_POLICY_URL"] ?? "",
  CATALOG_POLICY_URL: process.env["NEXT_PUBLIC_CATALOG_POLICY_URL"] ?? "",
  MARKETING_PROMOTION_SERVICE_USERNAME:
    process.env["NEXT_PUBLIC_MARKETING_PROMOTION_SERVICE_USERNAME"] ?? "",
  MARKETING_PROMOTION_SERVICE_PASSWORD:
    process.env["NEXT_PUBLIC_MARKETING_PROMOTION_SERVICE_PASSWORD"] ?? "",
  SUBBACOUNT_USER_LIMIT: process.env["NEXT_PUBLIC_SUBBACOUNT_USER_LIMIT"]
    ? Number(process.env["NEXT_PUBLIC_SUBBACOUNT_USER_LIMIT"])
    : 5,
  NEXT_ALLOW_SESSION_REPLAY_ON_CATALOG_IDS:
    process.env["NEXT_PUBLIC_ALLOW_SESSION_REPLAY_ON_CATALOG_IDS"]?.split(
      ","
    ) ?? [],
};

export default env;
