"use client";

import { memo, useMemo } from "react";

import { getInitials } from "@saas/account/utils";
import { classNames } from "@saas/shared/utils";

import { useSessionProfile } from "../@hook/session-profile/use-session-profile";

export interface AvatarProps {
  active?: boolean;
  className?: string;
}

export const Avatar = memo(({ active, className }: AvatarProps) => {
  const { profile } = useSessionProfile();

  const { first, last } = useMemo(() => {
    if (!profile?.name) return { first: "", last: undefined };

    return getInitials(profile.name);
  }, [profile?.name]);

  return (
    <span
      className={classNames(
        "bg-blue-B800 text-invert label-small md:label-large grid h-6 w-6 place-content-center rounded-full border md:h-9 md:w-9",
        active ? "border-blue-B100" : "border-transparent",
        className
      )}
    >
      {first}
      {last}
    </span>
  );
});

Avatar.displayName = "Avatar";

export default Avatar;
